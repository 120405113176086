import { registerApplication, start } from "single-spa";
import {
  constructApplications,
  constructRoutes,
  constructLayoutEngine,
} from "single-spa-layout";
import microfrontendLayout from "./microfrontend-layout.html";

let piSessionScript = document.createElement("script");
piSessionScript.src = `https://login-stg.pearson.com/v1/piapi-int/login/js/v2/session.js`;
document.body.appendChild(piSessionScript);

let inputPiUserId = document.createElement("input");
inputPiUserId.type = "hidden";
inputPiUserId.id = "pi_user_id";
document.body.appendChild(inputPiUserId);
let isApplicationStart = false;

const loadApplication = () => {
  let data = {
    props: {
      env: "STG",
      userId: window?.piSession?.userId(),
      userToken: window?.piSession?.currentToken()
    },
  };
  const routes = constructRoutes(microfrontendLayout, data);
  const applications = constructApplications({
    routes,
    loadApp({ name }) {
      return System.import(name);
    },
  });
  
  const layoutEngine = constructLayoutEngine({ routes, applications }); 
  applications.forEach(registerApplication);
  layoutEngine.activate();
  start();
  isApplicationStart = true;  
};

function startPiSession() {
  let session = window.piSession;
  if (!session) return console.error("piSession did not load!");
  window.piSession.initialize("xyOsQkQkwkCXGyNgGCjZpnIiJmOtFPov", {
    sessionIdleTimeoutSeconds: 1800,
  });

  window.piSession.on(window.piSession.RefreshEvent, function (eventData) {
    if (session.hasValidSession(10) !== "success") {
      let params = new URLSearchParams(window.location.search);
      window.piSession.login(
        `${window.location.origin}${
          window?.location.pathname
        }?${params.toString()}`
      );
    } else {
      if (!isApplicationStart) {
        loadApplication();  
      }
      
    }
  });

  session.on(session.SessionStateKnownEvent, function () {
    if (session.hasValidSession(10) !== "success") {
      let params = new URLSearchParams(window.location.search);
      window.piSession.login(
        `${window.location.origin}${
          window?.location.pathname
        }?${params.toString()}`
      );
    }

    session.on(session.LoginEvent, function () {
      if (session.hasValidSession(10) === "success") {
        setTimeout(function () {
          window.location.reload();
        }, 2000);
      }
    });
    session.on(session.LogoutEvent, function () {
      let params = new URLSearchParams(window.location.search);
      window.piSession.login(
        `${window.location.origin}${
          window?.location.pathname
        }?${params.toString()}`
      );
    });
  });
}

piSessionScript.onload = startPiSession;
